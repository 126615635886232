import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const WritingPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Writing"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>
            I am a software engineer, a former sailor and a proud AFSer. I am an
            interdisciplinary creator who likes to blur the borders between arts
            and technology.
          </h2>
          <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Large imagery is at the heart of this theme</figcaption>
          </figure>
          <h3 id="dynamic-styles">Biography</h3>
          <p>
            Currently I live in New York City and work at Chartbeat, where I
            work with and build tools for newsrooms around the globe.
          </p>
          <p>
            I believe stories help us make sense of life and communicate the
            most complex aspects of human nature.I like to tell stories and
            develop technology that helps others do the same.
          </p>
          <p>
            I was born and raised in Istanbul where I went to the Austrian High
            School. I lived in Spain and the United States, traveled more and
            learned 4 languages along the way. Right now, apart from my work at
            Chartbeat, I am learning Persian and drawing; I am also making a
            short film...
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <WritingPage location={props.location} data={data} {...props} />
    )}
  />
)
